import * as React from 'react';
import { connect } from 'react-redux';

import * as types from '../../../actions/actionTypes';
import ResponsiveDialog from '../../utility/ResponsiveDialog';
import SignupDialogContents from './SignupDialogContents';
import { isMobile } from '../../../lib/getDeviceSize';

export interface Props {
  dispatch: any;
  open: boolean;
}

/**
 * @class SignupDialog
 * @extends {React.Component}
 */
export const SignupDialog: React.FC<Props> = props => {
  const { open, dispatch } = props;

  const handleClose = () => {
    if (open) {
      dispatch({ type: types.CloseSignupDialog });
      // Clear the signup callback if the user closes the Modal
      dispatch({
        type: types.ClearSignupCallback,
      });
    }
  };

  return (
    <ResponsiveDialog
      mediumLargerPaper={isMobile() ? false : true}
      onClose={() => handleClose()}
      open={open}
      className="save-dialog"
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName="save-dialog-closeButton"
    >
      <SignupDialogContents />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.dialogs.signupDialogOpen,
});

export default connect(mapStateToProps)(SignupDialog);
