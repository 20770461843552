import { isMobile } from '../../../lib/getDeviceSize';

export const styles = {
  accountLink: {
    color: '#463264',
    fontSize: 14,
    marginTop: '8px',
  },
  accountLinkButton: {
    color: '#463264',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'initial' as 'initial',
  },
  firstNameField: {
    height: 43,
    marginRight: 8,
    maxWidth: 190,
  },
  formFieldStyle: {
    width: '100%',
  },
  shortNameField: {
    height: 63,
    marginRight: 8,
    maxWidth: 142,
  },
  tall: {
    height: 40,
    marginBottom: 25,
  },
};
