import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import * as types from '../../../actions/actionTypes';
import Messages from '../../utility/Messages';
import SignupForm from '../../utility/SignupForm';
import { styles } from '../../utility/SignupForm/styles';
import track from '../../../lib/track';
import EventEmitter from '../../../lib/eventEmitter';

import style from '../../../styles/sass/components/SignupDialog.module.scss';

export interface Props {
  dispatch: any;
  messages: any;
  postAuthPath: string;
  signupSubtext: string | undefined;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
  entrancePath: string;
}

export interface State {
  tracked: boolean;
}

export const SignupDialogContents: React.FC<Props> = ({
  dispatch,
  messages,
  postAuthPath,
  signupSubtext,
  utmCampaign,
  utmMedium,
  utmSource,
  utmTerm,
  entrancePath,
}) => {
  const [tracked, setTracked] = useState<boolean>(false);
  const [signupSuccess, setSuccess] = useState(false);
  const signupRef = React.useRef<HTMLInputElement>(null);

  const haveAccount = () => {
    dispatch({ type: types.CloseSignupDialog });
    dispatch({
      postAuthPath: postAuthPath,
      type: types.OpenLoginDialog,
    });
  };

  const _handleSignupSuccess = () => setSuccess(true);

  useEffect(() => {
    EventEmitter.on('signupSuccess', _handleSignupSuccess);
    if (!tracked) {
      track.modalView('Signup Dialog');
      setTracked(true);
    }
    return () => {
      setSuccess(false);
      EventEmitter.removeListener('signupSuccess', _handleSignupSuccess);
    };
  }, []);

  useEffect(() => {
    if (messages.error && Object.keys(messages.error).length !== 0) {
      if (signupRef && signupRef.current) {
        signupRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [messages]);

  const renderSigninText = () => {
    return (
      <p style={styles.accountLink}>
        Already have an account?
        <Button
          color="primary"
          style={styles.accountLinkButton}
          onClick={() => {
            haveAccount();
          }}
        >
          SIGN IN
        </Button>
      </p>
    );
  };

  return (
    <div className="save-dialog-inner" ref={signupRef}>
      <div className={style.signupDialogFormContainerInner}>
        <Messages messages={messages} />
        {signupSuccess ? (
          <div className="signup-success">
            <h2 className="title">Account Created!</h2>
            <svg
              width={269}
              height={241}
              viewBox="0 0 269 241"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: 50 }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M132.952.214c21.386 1.527 39.414 13.78 57.476 25.468 19.309 12.496 38.611 24.767 50.651 44.504 14.754 24.186 35.86 53.131 24.866 79.287-11.132 26.487-50.677 21.984-74.219 38.136-22.284 15.288-32.039 49.892-58.774 53.106-26.859 3.228-47.9-21.681-69.874-37.643-22.12-16.07-48.534-29.446-58.028-55.273-9.647-26.244-4.592-56.5 7.722-81.576 11.333-23.079 34.113-36.9 56.494-49.192C89.033 6.174 110.518-1.388 132.952.214z"
                fill="#195A50"
              />
              <path
                d="M182.281 90.096l-60.812 60.81-30.406-30.403"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <SignupForm
            postAuthPath={postAuthPath}
            shrinkWidth
            utmCampaign={utmCampaign}
            utmMedium={utmMedium}
            utmSource={utmSource}
            utmTerm={utmTerm}
            entrancePath={entrancePath}
            signupText={signupSubtext}
          >
            {renderSigninText()}
          </SignupForm>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
  postAuthPath: state.auth.postAuthPath,
  signupSubtext: state.dialogs.signupSubtext,
  utmCampaign: state.auth.utmCampaign,
  utmMedium: state.auth.utmMedium,
  utmSource: state.auth.utmSource,
  utmTerm: state.auth.utmTerm,
  entrancePath: state.auth.entrancePath,
});

export default connect(mapStateToProps)(SignupDialogContents);
